<template>
  <div class="app-home">
    <!-- 顶部 -->
    <HeaderLayout></HeaderLayout>

    <!-- 列表 -->
     
  </div>
</template>

<script>
import base from "@/common/base.js";
import HeaderLayout from "./Home/layout/HeaderLayout.vue";

export default {
  name: "home",
  mixins: [base],
  components: { HeaderLayout },
  data() {
    return {
      loading: false,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import url("../views/Home/style/home.scss");
</style>